import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';

class RevCarousel extends Component {
  render() {
    let {
      images,
      height = '100vh',
      showThumbs,
      autoPlay,
      showIndicators,
      showStatus,
      infiniteLoop,
    } = this.props;
    return (
      <Carousel
        showThumbs={showThumbs}
        autoPlay={autoPlay}
        showIndicators={showIndicators}
        showStatus={showStatus}
        infiniteLoop={infiniteLoop}
      >
        {images.map(
          (img, idx) =>
            /* seems showThumbs must contain <img /> */
            showThumbs ? (
              <div
                key={idx}
                style={{
                  height: '100%',
                  backgroundColor: 'white',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img src={img.source} alt="image"/>
              </div>
            ) : (
              <RevCarousel.ItemWrapper height={height} key={idx} image={img.source} position={img.logoPosition} />
            )
        )}
      </Carousel>
    );
  }
}

RevCarousel.ItemWrapper = styled.div`
  border: 20px solid white;
  background-image: url(${props => props.image});
  width: 100%;
  height: ${props => props.height};
  background-position: ${props => props.position ? props.position : 'center'};
  background-size: cover;

  @media screen and (max-width: 700px) {
    height: 300px;
  }
`;

export default RevCarousel;
