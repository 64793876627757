import React from 'react';
import styled from 'styled-components';
import * as Widget from './Widget';
import * as L from '../Utils/Lang';
import Link from './Link';

class IntroduceSection extends React.Component {
  render() {
    return (
      <Wrapper>
        <Widget.Center>
          <Content>
            <h3>
              <p style={{ right: 30 }}>{L.s('about-title-1')}</p>
              <p style={{ left: 30 }}>{L.s('about-title-2')}</p>
            </h3>
            <h4>{L.s('about-subtitle')}</h4>
            <h5>{L.s('about-description')}</h5>
          </Content>
        </Widget.Center>
        <Link to={'/about'}>
          <ButtonPosition>
            <Widget.FlatButton
              backgroundColor={'#705577'}
              hoverColor={'#705577'}
              style={{ color: 'white', margin: '0px 50px' }}
              labelStyle={{verticalAlign: 'bottom'}}
              label={'read more'}
            />
          </ButtonPosition>
        </Link>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 40px 80px;
  background-color: #F0F0F0;

  & > h1 {
    margin: 0px auto;
  }

  @media screen and (max-width: 600px) {
    padding: 40px;

    & > h1 {
      margin-bottom: 0;
    }
  }
`;

const Content = styled.div`
  display: flex;
  padding: 40px 25px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  line-height: 40px;
  & > h3 {
    font-size: 130%;
    text-align: center;
    color: #442c4c;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    letter-spacing: 15px;
    & > p{
      position: relative; 
    }
    & > p::first-letter {
      font-size: 180%;
      margin-right: 1px;
    }
  }
  @media screen and (max-width: 530px) {
    & > h3 {
      display: flex;
      flex-direction: column;
    }
  }
  & > h4 {
    font-size: 110%;
    color: #565656;
    margin-bottom: 20px;
    letter-spacing: 5px;
  }

  & > h5 {
    font-size: 100%;
    color: #6b6b6b;
    white-space: pre-wrap;
    line-height: 30px;
  }

  ${props => props.extraCss};

  @media screen and (max-width: 600px) {
    flex-direction: column;

    & > div {
      margin-right: 0;
    }

    & > figure {
      width: 100%;
      max-width: 100%;
    }
  }
`;
const ButtonPosition = styled.div`
  text-align: right;
  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

export default IntroduceSection;
