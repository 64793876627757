import React from 'react';
import styled from 'styled-components';
import { withPage } from '../Page';
import RevCarousel from '../Components/RevCarousel';
import IntroduceSection from '../Components/IntroduceSection';
import PolygonGroup from '../Components/PolygonGroup';
import FeatureCollectionSection from '../Components/FeatureCollectionSection';
import PathPrefix from '../Utils/PathPrefixUtil';
import * as Widget from '../Components/Widget';
import * as Icon from '../Components/Icon';
import * as Scroll from 'react-scroll';

let ImageArray = [
  { source: PathPrefix('/images/pages/home/home_banner_1.jpg'), logoPosition: 'bottom center' },
  { source: PathPrefix('/images/pages/home/home_banner_2.jpg'), logoPosition: 'bottom center' },
  { source: PathPrefix('/images/pages/home/home_banner_3.jpg'), logoPosition: null },
  { source: PathPrefix('/images/pages/home/home_banner_4.jpg'), logoPosition: 'bottom right' },
  { source: PathPrefix('/images/pages/home/home_banner_5.jpg'), logoPosition: 'bottom right' }
];

class HomePage extends React.Component {
  render() {
    return (
      <Wrapper>
        <RevCarousel
          showThumbs={false}
          autoPlay={true}
          /* 70px is the navbar height */
          height={"calc(100vh - 70px)"} 
          images={ImageArray}
          showStatus={false}
          showIndicators={false}
          infiniteLoop={true}
        />

        <Widget.Row extraCss="position: relative" justify="center">
          <Scroll.Link
            style={{
              position: 'absolute',
              top: -30,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '100%',
              cursor: 'pointer',
            }}
            to="categories-section"
            smooth={true}
            offset={-60}
            duration={500}
          >
            <Icon.ArrowDropDownCircle
              color="#c5c5c5"
              style={{ width: 80, height: 80 }}
              hoverColor="#a8a7a8"
            />
          </Scroll.Link>
        </Widget.Row>
        <IntroduceSection />
        <Scroll.Element name="categories-section">
          <Widget.MaxWidth width={1000}>
            <PolygonGroup />
            <FeatureCollectionSection />
          </Widget.MaxWidth>
        </Scroll.Element>
      </Wrapper>
    );
  }

  _scrollToTop = () => {
    Scroll.animateScroll.scrollToTop();
  };
}

const Wrapper = styled.div``;

export default withPage(HomePage);
